<template>
  <div class="giftcardpurchase">
    <v-sheet elevation="2" class="pa-6">
      <v-row>
        <v-col class="text-center">
          <p>If you would like to send this gift card via email please enter the email address below. Note that the email will be sent immediately upon checkout. If no email address is provided the gift card will be emailed to you.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            label="Email Address"
            v-model="email"
            hide-details
            :rules="emailRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn depressed x-large block color="gray" @click="skip">Skip</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn :disabled="!canSubmit" depressed x-large block color="primary" @click="search">Submit</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'GiftCardGifteeSearch',

  data() {
    return {
      submitPending: false,
      email: null,
      emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]
    }
  },

  computed: {
    canSubmit() {
      if (this.submitPending) return false
      return this.email && this.email.length > 5
    }
  },

  methods: {
    async search() {
      this.submitPending = true
      await this.$store.dispatch('checkGifteeExists', this.email)
        .then((res) => {
          console.log(res)
          this.$store.commit('setGifteeId', res.gifteeId)
          this.$store.commit('setGifteeEmail', this.email)
          this.submitPending = false

          // Clear the gift card coupon
          this.$store.state.coupon = null

          this.$router.push({ name: 'CartView', query: { mtek_id: this.$store.state.mtek_id } })
        })
        .catch((err) => {
          console.log(err)
          this.submitPending = false
        })
    },
    skip() {
      this.$store.commit('setGifteeEmail', this.$store.state.user.email)
      this.$router.push({ name: 'CartView', query: { mtek_id: this.$store.state.mtek_id } })
    }
  }
}
</script>

<style lang="scss">

</style>