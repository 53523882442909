<template>
  <div class="gift-card-nav">
    <v-row justify="center">
      <v-col cols="8">
        <v-sheet elevation="2" class="pa-7">
          <v-row>
            <v-col>
              <v-btn
                x-large
                color="primary"
                depressed
                block
                @click="goToPurchase"
              >
                Purchase Gift Card
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                x-large
                color="primary"
                outlined
                depressed
                block
                @click="goToRedeem"
              >
                Redeem Gift Card
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'GiftCardNav',

  methods: {
    goToPurchase() {
      this.$router.push({ name: 'GiftCardPurchase' })
    },
    goToRedeem() {
      this.$router.push({ name: 'GiftCardRedeem' })
    }
  }
}
</script>

<style lang="scss">

</style>