import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goToGiftCard: false,
    session: {},
    user: {},
    challenge: null,
    tracking: null,
    baseEccoUrl: process.env.VUE_APP_BASE_ECCO_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    state: '39fh03fhj30gh0jfowej0fifjasodin0jhkg2',
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    marianaTekUri: process.env.VUE_APP_MARIANA_TEK_URI,
    marianaBaseUri: process.env.VUE_APP_BASE_MARIANA_URI,
    code: null,
    locations: [],
    locationsLoading: false,
    cart: {},
    cartLoading: false,
    cartLoadingError: null,
    mtek_id: null,
    coupon: null,
    checkoutPending: false,
    checkoutPendingMessage: null,
    checkoutError: null,
    checkoutErrored: false,
    checkoutSuccess: false,
    require_signup: 0,
    require_card: 0,
    order: {},
    accountBalanceApplied: 0,
    stripeConfig: null,
    paymentIntent: null,
    setupIntent: null,
    setupIntentConfirm: null,
    stripePartnerId: process.env.VUE_APP_STRIPE_PARTNER_ID,
    pendingPurchaseAgreement: null,
    purchaseAgreement: null,
    signedPurchaseAgreement: null,
    signatureImage: null,
    login_only: 0,
    prompt: 0,
    signupError: null,
    config: null,
    banner_text: null,
    desktop_image_url: null,
    mobile_image_url: null,
    giftCardConfigs: null,
    giftCardConfigId: null,
    gifteeId: null,
    gifteeEmail: null,
    transactionId: null,
    instance: null,
  },

  mutations: {
    setSession: (state, val) => {
      state.session = val
    },
    setUser: (state, val) => {
      state.user = val
    },
    setChallenge: (state, val) => {
      state.challenge = val
    },
    setTracking: (state, val) => {
      state.tracking = val
    },
    setCode: (state, val) => {
      state.code = val
    },
    setProducts: (state, val) => {
      state.products = val
    },
    setProductsLoading: (state, val) => {
      state.productsLoading = val
    },
    setLocations: (state, val) => {
      state.locations = val
    },
    setLocationsLoading: (state, val) => {
      state.locationsLoading = val
    },
    setCart: (state, val) => {
      state.cart = val
    },
    setCartLoading: (state, val) => {
      state.cartLoading = val
    },
    setCartLoadingError: (state, val) => {
      state.cartLoadingError = val
    },
    setCheckoutPending: (state, val) => {
      state.checkoutPending = val
    },
    setCheckoutPendingMessage: (state, val) => {
      state.checkoutPendingMessage = val
    },
    setCheckoutErrored: (state, val) => {
      state.paymentErrored = val
    },
    setCheckoutError: (state, val) => {
      state.paymentError = val
    },
    setCheckoutSuccess: (state, val) => {
      state.checkoutSuccess = val
    },
    setOrder: (state, val) => {
      state.order = val
    },
    setStripeConfig: (state, val) => {
      state.stripeConfig = val
    },
    setPaymentIntent: (state, val) => {
      state.paymentIntent = val
    },
    setSetupIntent: (state, val) => {
      state.setupIntent = val
    },
    setSetupIntentConfirm: (state, val) => {
      state.setupIntentConfirm = val
    },
    setPendingPurchaseAgreement: (state, val) => {
      state.pendingPurchaseAgreement = val
    },
    setPurchaseAgreement: (state, val) => {
      state.purchaseAgreement = val
    },
    setSignedPurchaseAgreement: (state, val) => {
      state.signedPurchaseAgreement = val
    },
    setSignatureImage: (state, val) => {
      state.signatureImage = val
    },
    setConfig: (state, val) => {
      state.config = val
    },
    setGiftCardConfigs: (state, val) => {
      state.giftCardConfigs = val
    },
    setGoToGiftCard: (state, val) => {
      state.goToGiftCard = val
    },
    setGifteeId: (state, val) => {
      state.gifteeId = val
    },
    setGifteeEmail: (state, val) => {
      state.gifteeEmail = val
    },
    setGiftCardConfigId: (state, val) => {
      state.giftCardConfigId = val
    },
    setTransactionId: (state, val) => {
      state.transactionId = val
    },
    setInstance: (state, val) => {
      state.instance = val
    }
  },

  actions: {
    createAccount: ({ dispatch, commit, state }, data) => {
      axios.post(`${state.baseUrl}/me/account`, data).then((res) => {
        dispatch('signupAuthenticate', res.headers['x-ephemeral-token'])
        commit('setUser', res.data)
      }).catch((err) => {
        console.log(err.response)
        if (err.response.data?.email?.length > 0) {
          state.signupError = err.response.data.email[0]
        }
      })
    },

    authenticate: async ({ commit, dispatch, state }) => {
      axios.post(`${state.marianaBaseUri}/o/token/?client_id=${state.clientId}&code_verifier=${state.challenge}&code=${state.code}&grant_type=authorization_code&redirect_uri=${state.redirectUri}`)
      .then(async (data) => {
        commit('setSession', data.data)
        await dispatch('getProfile')
        if (state.goToGiftCard === false) {
          router.push({ name: 'CartView' })
        } else {
          router.push({ name: 'GiftCardNavRoute' })
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    },

    signupAuthenticate: ({ state }, data) => {
      window.location.href = `${state.marianaBaseUri}/o/authorize?response_type=code&client_id=${state.clientId}&redirect_uri=${state.redirectUri}&code_challenge=${state.challenge}&ephemeral_token=${data}`
    },

    getLocations: ({ commit, state }) => {
      commit('setLocationsLoading', true)
      axios.get(`${state.baseEccoUrl}/mariana/location`).then((res) => {
        commit('setLocations', res.data.data)
        commit('setLocationsLoading', false)
      }).catch((err) => {
        console.log(err.response)
        commit('setLocationsLoading', false)
      })
    },

    getConfig: async ({ commit, state }, id) => {
      await axios.get(`${state.baseEccoUrl}/landing-page-config/${id}`).then((res) => {
        console.log(res)
        commit('setConfig', res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    },

    getProfile: async ({ commit, dispatch, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseUrl}/me/account`, {
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`
          }
        }).then(async (res) => {
          commit('setUser', res.data)
          resolve('user set')
          dispatch('createTracking', {
            customerId: res.data.id,
            customerName: res.data.full_name,
            customerEmail: res.data.email,
            productId: state.mtek_id,
            checkoutStep: 'LOGGED_IN',
          })
        }).catch((err) => {
          console.log(err.response)
          reject('error getting profile')
        })
      })
    },

    generateCart: async ({ state, dispatch, commit }) => {
      return new Promise((resolve, reject) => {
        //commit('setCartLoading', true)
        axios.post(`${state.baseUrl}/locations/${state.user.home_location.id}/cart/add_product_listing`, {
        //axios.post(`${state.baseUrl}/locations/48733/cart/add_product_listing`, {
            product_listing_id: state.mtek_id,
            quantity: 1,
          },
          {
            headers: {
              'Authorization': `Bearer ${state.session.access_token}`
            },
        }).then(async (res) => {
          commit('setCart', res.data)
          if (state.coupon !== null) await dispatch('applyDiscount')

          //commit('setCartLoading', false)
          resolve('cart generated')
        }).catch(function (error) {
          if (error.response) {
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            commit('setCartLoading', false)
            if (error.response.data?.non_field_errors) {
              commit('setCartLoadingError', error.response.data?.non_field_errors[0])
            } else {
              commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
            }
          } else if (error.request) {
            commit('setCartLoading', false)
            commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          }
    
          reject('error generating cart')
        });
      })
    },

    getCart: async ({ state, dispatch, commit }) => {
      return new Promise((resolve, reject) => {
        commit('setCartLoading', true)
        axios.get(`${state.baseUrl}/locations/${state.user.home_location.id}/cart`,{
        //axios.get(`${state.baseUrl}/locations/48733/cart`,{
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`
          },
        }).then((res) => {
          commit('setCart', res.data)
          commit('setCartLoading', false)
          dispatch('updateTracking', {
            checkoutStep: 'CART_GENERATED'
          })
          resolve('cart loaded')
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          commit('setCartLoading', false)
          commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          dispatch('updateTracking', {
            checkoutStep: 'CART_ERRORED'
          })
          reject('error loading cart')
        });
      }) 
    },

    clearCart: async ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseUrl}/locations/${state.user.home_location.id}/cart/clear`, {},
        //axios.post(`${state.baseUrl}/locations/48733/cart/clear`, {},
          {
            headers: {
              'Authorization': `Bearer ${state.session.access_token}`
            },
        }).then(() => {
          commit('setCart', {})
          resolve('cart cleared')
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          commit('setCartLoading', false)
          commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          reject('error clearing cart')
        });
      })
    },

    redeemProductCheckoutCart: async ({ state }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseUrl}/locations/${state.user.home_location.id}/cart/checkout`, {
          payments:
            [
              {
                amount: 0,
                payment_method_type: 'account',
              }
            ],
          },
          {
            headers: {
              'Authorization': `Bearer ${state.session.access_token}`
            },
          }
        )
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
      })
    },

    applyDiscount: async ({ state, commit }) => {
      return new Promise((resolve, reject) => {48733
        axios.post(`${state.baseUrl}/locations/${state.user.home_location.id}/cart/apply_discount_code`, {
        //axios.post(`${state.baseUrl}/locations/48733/cart/apply_discount_code`, {
          code: state.coupon,
        },
          {
            headers: {
              'Authorization': `Bearer ${state.session.access_token}`
            },
        }).then((res) => {
          commit('setCart', res.data)
          resolve('cart discount applied')
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          commit('setCartLoading', false)
          commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          reject('error applying cart discount')
        });
      })
    },

    getStripeConfig: async ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/mariana/stripe-configuration`, {
          cart: state.cart.id
        })
        .then((res) => {
          //console.log(res)
          commit('setStripeConfig', res.data)
          resolve('stripe config loaded')
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
            commit('setPaymentErrored', true)
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          commit('setCartLoading', false)
          commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          reject('error loading stripe config')
        });
      })
    },

    createStripePaymentIntent: async ({ state, dispatch, commit }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/mariana/stripe-payment-intent`, {
          cart: state.cart.id,
          amount: state.cart.total,
          account_balance_payment_amount: state.accountBalanceApplied,
          payment_type: 'card',
        })
        .then((res) => {
          //console.log(res)
          commit('setPaymentIntent', res.data)
          dispatch('updateTracking', {
            checkoutStep: 'STRIPE_PAYMENT_INTENT_GENERATED'
          })
          resolve('payment intent created')
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          commit('setCartLoading', false)
          commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          dispatch('updateTracking', {
            checkoutStep: 'STRIPE_PAYMENT_INTENT_ERRORED'
          })
          reject('error creating payment intent')
        });
      })
    },
    createStripeSetupIntent: async ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/mariana/stripe-setup-intent`, {
          user: state.user.id,
          collection_method: 'manual',
        })
        .then((res) => {
          //console.log(res)
          commit('setSetupIntent', res.data)
          resolve('setup intent created')
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          commit('setCartLoading', false)
          commit('setCartLoadingError', 'There was an issue creating your cart, please try again!')
          reject('error creating payment intent')
        });
      })
    },
    storePaymentMethod: async ({ state, commit }, data) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/mariana/stripe-store-payment-method`, {
          user: state.user.id,
          partner: state.stripePartnerId,
          ...data,
        })
          .then((res) => {
            if (res.data) {
              commit('setSetupIntentConfirm', res.data)
              resolve('setup intent confirmed')
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    saveNewCustomerCard: async ({ state }, data) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseUrl}/me/credit_cards`, {
            ...data,
          },
          {
            headers: {
              'Authorization': `Bearer ${state.session.access_token}`
            },
          }
        )
          .then((res) => {
            console.log(res)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    getPendingPurchaseAgreement: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/mariana/pending-purchase-agreement?cart_id=${state.cart.id}`, {
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`
          }
        }).then((res) => {
          console.log(res)
          if (res.data) {
            if (res.data.results.length > 0) {
              commit('setPendingPurchaseAgreement', res.data.results[0])
              resolve('purchase agreement set')
            } else {
              commit('setPendingPurchaseAgreement', null)
              resolve('no pending purchase agreement')
            }
          } else {
            commit('setPendingPurchaseAgreement', null)
            resolve('no pending purchase agreement')
          }
        }).catch((err) => {
          if (err.response) reject('error getting pending purchase agreement')
        })
      })
    },
    getPurchaseAgreement: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.marianaBaseUri}/api/documents/v1/purchase_agreements/${state.pendingPurchaseAgreement?.pending_signatures[0].purchase_agreement_id}`, {
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`
          }
        }).then((res) => {
          console.log(res)
          if (res.data) {
            commit('setPurchaseAgreement', res.data)
            resolve('purchase agreement set')
          } else {
            commit('setPurchaseAgreement', null)
            resolve('no purchase agreement')
          }
        }).catch((err) => {
          if (err.response) reject('error getting purchase agreement')
        })
      })
    },
    signPurchaseAgreement: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        const form = new FormData()
        form.append('agreement_id', state.purchaseAgreement.agreement_id)
        form.append('purchase_agreement_requirement_id', state.pendingPurchaseAgreement.pending_signatures[0].purchase_agreement_requirement_id)
        form.append('user_id', state.user.id)
        form.append('cart_line_id', state.cart.line_items[0].id)
        form.append('signature_image_file', state.signatureImage, 'signature.png')
        const date = new Date
        form.append('timestamp', date.toISOString())
        //axios.post(`${state.baseEccoUrl}/mariana/sign-purchase-agreement`, form, {
        axios.post(`${state.marianaBaseUri}/api/documents/v1/purchase_agreements/${state.purchaseAgreement.agreement_id}/sign`, form, {
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
          }
        }).then((res) => {
          console.log(res)
          if (res.data) {
            commit('setSignedPurchaseAgreement', res.data)
            resolve('signed purchase agreement set')
          } else {
            commit('setSignedPurchaseAgreement', null)
            resolve('no signature')
          }
        }).catch((err) => {
          console.log(err.response)
          reject('error getting signed purchase agreement')
        })
      })
    },
    async createTracking({ state, commit }, data) {
      const tracking = await axios.post(`${state.baseEccoUrl}/analytics/landing`, data)
      commit('setTracking', tracking.data)
    },
    async updateTracking({ state, commit }, data) {
      if (state.tracking) {
        const tracking = await axios.patch(`${state.baseEccoUrl}/analytics/landing/${state.tracking.id}`, data)
        commit('setTracking', tracking.data)
      }
    },

    /* @@@
      Gift Card Actions
    */

    async getGiftCardConfigs({ state, commit }) {
      const configs = await axios.get(`${state.baseEccoUrl}/gift-card/config?public=true`)
      commit('setGiftCardConfigs', configs.data)
    },

    async checkGifteeExists({ state }, email) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/gift-card/giftee-check`, {
          email,
        }).then(res => {
          console.log(res)
          if (res.data) {
            resolve(res.data)
          } else {
            reject()
          }
        }).catch(err => reject(err))
      })
    },

    async createGiftCardInstance({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/gift-card/instance`, data)
          .then((res) => {
            console.log(res)
            if (res) {
              commit('setGifteeEmail', null)
              commit('setGifteeId', null)
              commit('setGiftCardConfigId', null)
              resolve('Gift Card Purchased!')
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },

    async patchGiftCardInstance({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${state.baseEccoUrl}/gift-card/instance/${state.instance.id}`, {
          isRedeemed: true,
        })
        .then((res) => {
          commit('setInstance', res.data)
          resolve()
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
      })
    }
  },

  getters: {
    isAuthenticated(state) {
      return (state.session.access_token && state.session.access_token.length > 0)
    },
    productsLoading(state) {
      return state.productsLoading
    },
    locationsLoading(state) {
      return state.locationsLoading
    },
  },

  modules: {
  },

  plugins: [createPersistedState()],
})