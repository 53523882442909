<template>
  <v-container class="giftcardmain">
    <v-row class="justify-center">
      <v-col cols="8">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GiftCardMain',

  beforeMount() {
    if (this.$store.state.session.access_token === undefined) {
      this.$store.commit('setGoToGiftCard', true)
      this.$router.push({ name: 'SignupView' })
    } else {
      this.$store.commit('setGoToGiftCard', false)
    }
  }
}
</script>

<style lang="scss">

</style>