<template>
  <v-container class="giftcardredeem">
    <v-row class="justify-center">
      <v-col cols="6">
        <v-sheet elevation="10" class="pa-8" v-if="!instance">

          <div v-show="user.id">
            <div class="text-center">
              <p class="mb-2">Logged in as {{ user.full_name }} ({{ user.email }})</p>
              <small><a href="#" @click.prevent="logout">Is this not you?</a></small>
            </div>
            <v-divider class="mt-2 mb-5" />
          </div>

          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Redeem Your Gift Card</h2>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-5" />

          <v-alert v-if="submitError" type="error" dismissible @input="resetError">
            {{ submitErrorMessage }}
          </v-alert>

          <v-text-field 
            label="Redeem Code"
            outlined
            v-mask="'NNNN-NNNN-NNNN'"
            v-model="code"
          />

          <v-btn
            x-large
            depressed
            block
            color="primary"
            @click="redeemCode"
            :disabled="!canSubmit || submitPending"
            :loading="submitPending"
          >
            Redeem
          </v-btn>
        </v-sheet>

        <v-sheet elevation="10" class="pa-8" v-if="instance">
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Gift Card Redeemed!</h2>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-5" />

          <v-row>
            <v-col cols="12" v-if="giftCardConfig.isAccountBalance">
              <p>Your gift card for ${{ (instance.amount / 100).toFixed(2) }} has been redeemed. The balance can be found on your Ra Yoga account. To use it you can go to <a href="https://rayoga.com" target="_BLANK">rayoga.com</a> or use the Ra Yoga mobile app.</p>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" href="https://rayoga.com" block depressed>Rayoga.com</v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn link small @click="refreshRedeem" block depressed>Redeem Another Gift Card</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'GiftCardRedeem',

  data() {
    return {
      code: null,
      canSubmit: false,
      submitPending: false,
      submitSuccess: false,
      submitError: false,
      submitErrorMessage: null,
      showSuccess: false,
      instance: null,
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    originalLink() {
      return this.$store.state.originalLink
    },
    giftCardConfig() {
      return this.instance?.gift_card_config
    }
  },

  watch: {
    code(val) {
      this.code = val.toUpperCase()
      this.canSubmit = (val.length === 14)
    }
  },

  methods: {
    async redeemCode() {
      this.submitPending = true
      await axios.post(`${this.$store.state.baseEccoUrl}/gift-card/redeem`, {
        code: this.code,
        marianaUserId: this.user.id,
      })
        .then(async (res) => {
          console.log(res)
          if (res) {
            if (res.data.gift_card_config.isAccountBalance) {
              this.submitPending = false
              this.code = null
              this.instance = res.data
            } else {
              await this.$store.commit('setInstance', res.data)
              this.$router.push({ name: 'GiftCardRedeemProductRoute' })
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            this.submitError = true
            this.submitErrorMessage = err.response.data.message
            this.submitPending = false
          }
        })
    },
    resetError() {
      this.submitError = false
      this.submitErrorMessage = null
    },
    logout() {
      const originalLink = this.$store.state.originalLink
      window.localStorage.removeItem('vuex')
      window.location.href = originalLink + '&prompt=1'
    },
    refreshRedeem() {
      this.instance = null
      this.code = null
      this.submitPending = false
      this.submitError = false
      this.submitErrorMessage = false
    }
  },

  beforeMount() {
    // Clear the gift card coupon
    this.$store.state.coupon = null

    if (this.instance.isRedeemed || this.instance.isExpired) {
      this.$router.push({ name: 'GiftCardNavRoute' })
    }
  }
}
</script>

<style lang="scss">

</style>