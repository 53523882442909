<template>
  <v-container class="giftcardredeemproduct">
    <v-row class="justify-center">
      <v-col cols="8" v-if="canRender">

        <div v-if="step === 1">
          <v-sheet elevation="10" class="pa-8">
            <v-row justify="center" class="text-center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2>Purchase Agreement</h2>
                  </v-col>
                </v-row>
                <v-divider class="mt-2 mb-5" />
                <div style="width: 100%; max-height: 300px; padding: 15px; overflow-y: scroll; border: 1px solid #e2e2e3; text-align: left; margin-bottom: 20px;" v-html="purchaseAgreement.text"></div>
                
                <h4 class="text-center mb-2">Signature</h4>
                <div style="width: 100%; border: 1px solid #e2e2e3; margin-bottom: 15px;">
                  <VueSignaturePad width="100%" height="180px" ref="signaturePad" />
                </div>
                <v-btn color="primary" block depressed large @click.prevent="submitAgreement">I agree</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </div>

        <div v-if="step === 2">
          <v-sheet elevation="10" class="pa-8">
            <v-row justify="center" class="text-center">
              <v-col cols="12" v-show="stripeFormLoading">
                <heart-loader />
              </v-col>
              <v-col cols="12" v-show="!stripeFormLoading">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2>Add a Credit Card</h2>
                  </v-col>
                </v-row>
                <v-divider class="mt-2 mb-5" />

                <v-row>
                  <v-col cols="12">
                    <v-text-field dense v-model="name" outlined label="Name" />
                  </v-col>
                </v-row>
                
                <div id="payment-element"></div>
                <v-btn color="primary" :disabled="submitPending" :loading="submitPending" block depressed large @click.prevent="saveCreditCard">Submit</v-btn>
              
              </v-col>
            </v-row>
          </v-sheet>
        </div>

        <div v-if="step === 3">
          <v-sheet elevation="10" class="pa-8">
            <div v-show="user.id">
              <div class="text-center">
                <p class="mb-2">Logged in as {{ user.full_name }} ({{ user.email }})</p>
                <small><a href="#" @click.prevent="logout">Is this not you?</a></small>
              </div>
              <v-divider class="mt-2 mb-5" />
            </div>
            <v-row v-if="giftCardConfig">
              <v-col cols="12">
                 <p>{{ giftCardConfig.name }} will be redeemed to the currently logged in account.</p>
              </v-col>
            </v-row>
            <v-row justify="center" class="text-center">
              <v-btn :loading="confirmPending" :disabled="confirmPending" color="primary" block x-large @click="confirmRedeem">Confirm</v-btn>
            </v-row>
          </v-sheet>
        </div>

        <div v-if="step === 4">
          <v-sheet elevation="10" class="pa-8">
            <v-row justify="center" class="text-center">
              <v-col cols="6">
                <v-icon style="font-size: 9rem;">mdi-emoticon-happy-outline</v-icon>
                <h3 class="py-5">Gift card redeemed!</h3>
                <v-btn href="https://app.rayoga.com/my-account/?_mt=%2Faccount%2Fpersonal-information" depressed color="primary">My Account</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </div>

      </v-col>

      <v-col cols="12" v-if="!canRender" class="text-center">
        <heart-loader />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeartLoader from "@/components/heartLoader"
const Stripe = window.Stripe;

export default {
  name: 'GiftCardRedeemProduct',

  components: {
    HeartLoader
  },

  data() {
    return {
      canRender: false,
      step: 0,
      stripe: null,
      stripeFormLoading: false,
      elements: null,
      paymentElement: null,
      code: null,
      canSubmit: false,
      submitPending: false,
      submitSuccess: false,
      submitError: false,
      submitErrorMessage: null,
      showSuccess: false,
      name: null,
      postalcode: null,
      number: null,
      exp: null,
      ccv: null,
      confirmPending: false,
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    cart() {
      return this.$store.state.cart
    },
    cartLoading() {
      return this.$store.state.cartLoading
    },
    instance() {
      return this.$store.state.instance
    },
    giftCardConfig() {
      return this.instance?.gift_card_config
    },
    pendingPurchaseAgreement() {
      return this.$store.state.pendingPurchaseAgreement
    },
    purchaseAgreement() {
      return this.$store.state.purchaseAgreement
    },
    requiresAgreement() {
      return this.pendingPurchaseAgreement !== null && this.pendingPurchaseAgreement.pending_signatures.length > 0
    },
    hasAgreement() {
      return this.$store.state.signedPurchaseAgreement !== null
    },
  },

  watch: {
    step(val) {
      if (val === 2) {
        this.name = this.user.full_name
        this.$nextTick(() => {
          this.initialize()
        })
      }
    }
  },

  methods: {
    async initialize() {
      this.stripeFormLoading = true
      const { client_secret, stripe_publishable_api_key } = this.$store.state.setupIntent;
      this.stripe = Stripe(stripe_publishable_api_key)

      const appearance = {
        theme: 'stripe',
      };

      const style = {
        base: {
          color: '#303238',
          fontSize: '16px',
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF',
          },
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };

      this.elements = this.stripe.elements({ appearance, clientSecret: client_secret });

      this.cardElement = this.elements.create('card', { style });
      this.cardElement.mount("#payment-element");
      this.stripeFormLoading = false
    },
    async submitAgreement() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/png');
      if (!isEmpty) {
        console.log(data)
        await this.$store.commit('setSignatureImage', this.convertURItoBlob(data))
        await this.$store.dispatch('signPurchaseAgreement')
        this.step = 2
        this.name = this.user.full_name
      } else {
        alert('We need your signature')
      }
    },
    async saveCreditCard() {
      this.submitPending = true

      this.stripe.createPaymentMethod({
        elements: this.elements
      })
        .then((res) => {
          console.log(res)
          const { paymentMethod } = res

          if (paymentMethod) {
            this.stripe.confirmCardSetup(this.$store.state.setupIntent.client_secret, {
              payment_method: paymentMethod.id,
            })
            .then((res) => {
              if (res.setupIntent?.status === 'succeeded') {
                this.$store.dispatch('saveNewCustomerCard', {
                  first_name: this.name.split(' ')[0],
                  last_name: this.name.split(' ')[1],
                  number: paymentMethod.card.last4,
                  card_type: paymentMethod.card.brand,
                  expiration_month: paymentMethod.card.exp_month,
                  expiration_year: paymentMethod.card.exp_year,
                  postal_code: paymentMethod.billing_details.address.postal_code,
                  usage_location: this.$store.state.user.home_location.id,
                  partner_reference: paymentMethod.id,
                })

                this.step = 3
              }
              this.submitPending = false
            })
            .catch((err) => {
              console.log(err)
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.submitPending = false
        })
    },
    async confirmRedeem() {
      this.confirmPending = true
      await this.$store.dispatch('redeemProductCheckoutCart')
        .then((res) => {
          console.log(res)
          if (res.status === 'Completed') {
            this.$store.dispatch('clearCart')
            this.$store.commit('setCart', null)
            this.$store.dispatch('patchGiftCardInstance')
            // Clear the gift card coupon
            this.$store.state.coupon = null
            this.step = 4
          }
          this.confirmPending = false
        })
        .catch((err) => {
          console.log(err)
          this.confirmPending = false
        })
    },
    convertURItoBlob(dataurl) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataurl.split(',')[1]);

      // separate out the mime component
      //var mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], {type: 'image/png'});

      return blob;
      /*var arr = dataurl.split(','), //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:'png'});*/
    },
  },
  
  async beforeMount() {
    this.canRender = false
    if (this.instance.isRedeemed || this.instance.isExpired) {
      this.$router.push({ name: 'GiftCardNavRoute' })
    }

    if (this.instance === null) {
      this.$router.push({ name: 'GiftCardNavRoute' })
    }

    this.$store.state.mtek_id = `${this.instance.productType}-${this.instance.productId}`
    this.$store.state.coupon = 'V9L7R41UC0I6VGXAXSPS'
    await this.$store.dispatch('clearCart')
    await this.$store.dispatch('generateCart')
    await this.$store.dispatch('getStripeConfig')
    await this.$store.dispatch('createStripeSetupIntent')

    if (this.cart.are_purchase_agreements_required) {
      await this.$store.dispatch('getPendingPurchaseAgreement')
      if (this.$store.state.pendingPurchaseAgreement !== null) {
        console.log('getting purchase agreement')
        await this.$store.dispatch('getPurchaseAgreement')
      }
    }

    this.canRender = true

    if (this.requiresAgreement && !this.hasAgreement) {
      this.step = 1
    } else {
      if (this.giftCardConfig.requireSavedCard && this.user.credit_cards.length === 0) {
        this.step = 2
        this.name = this.user.full_name
        this.$nextTick(() => {
          this.initialize()
        })
      } else {
        this.step = 3
      }
    }
  }
}
</script>

<style lang="scss">

</style>