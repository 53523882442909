<template>
  <div class="giftcardpurchase">
    <v-sheet elevation="2" class="pa-4 py-8">
      <v-row v-if="configs">
        <v-col @click="selectConfig(config.id)" cols="12" v-for="config in configs" :key="config.id">
          <v-card class="config-item py-5 px-4" :class="{ 'selected': selectedConfig.id === config.id }">
            <v-row>
              <v-col cols="10">
                <h3>{{ config.name }}</h3>
              </v-col>
              <v-col cols="2">
                <v-chip color="primary">${{ config.marianaVariant.attributes.price }}</v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="configs">
        <v-col>
          <v-btn
            x-large
            block
            depressed
            color="primary"
            :disabled="!selectedConfig"
            @click="next"
          >
            Next
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!configs">
        <v-col class="text-center py-10">
          <heart-loader />
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import heartLoader from './heartLoader.vue'
export default {
  components: { heartLoader },
  name: 'GiftCardPurchase',

  data() {
    return {
      selectedConfig: {},
    }
  },

  computed: {
    configs() {
      return this.$store.state.giftCardConfigs?.data
    },
    productType() {
      if (this.selectedConfig.productType === 'accountbalance') return 'variants'
      return this.selectedConfig.productType
    }
  },

  methods: {
    selectConfig(id) {
      this.selectedConfig = this.configs.find(o => o.id === id)
    },
    next() {
      this.$store.commit('setGiftCardConfigId', this.selectedConfig.id)
      this.$store.state.mtek_id = `variants-${this.selectedConfig.giftCardVariantId}`
      this.$router.push({ name: 'GiftCardGifteeSearch', params: { configId: this.selectedConfig.id } })
    }
  },

  async beforeMount() {
    await this.$store.dispatch('getGiftCardConfigs')
  }
}
</script>

<style lang="scss">
.giftcardpurchase {
  .config-item {
    cursor: pointer;
    color: #3F523D;

    &.selected {
      border: 2px solid #3F523D;
      background-color: #909c8f;
      color: #fefefe;
    }
  }
}
</style>