import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Welcome from '../views/WelcomeView.vue'
import OAuthCallbackView from '../views/OAuthCallbackView.vue'
import SignupView from '../views/SignupView.vue'
import CartView from '../views/CartView'

import GiftCardMain from '../views/GiftCardMain'
import GiftCardNav from '../components/GiftCardNav'
import GiftCardRedeem from '../views/GiftCardRedeem'
import GiftCardPurchase from '../components/GiftCardPurchase'
import GiftCardGifteeSearch from '../components/GiftCardGifteeSearch'
import GiftCardRedeemProduct from '../views/GiftCardRedeemProduct.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: Welcome,
  },
  {
    path: '/cart',
    name: 'CartView',
    component: CartView,
  },
  {
    path: '/signup',
    name: 'SignupView',
    component: SignupView,
  },
  {
    path: '/oauth/callback',
    name: 'OAuthCallbackView',
    component: OAuthCallbackView,
  },

  {
    path: '/giftcard',
    name: 'GiftCardMain',
    component: GiftCardMain,

    children: [
      {
        path: '/',
        name: 'GiftCardNavRoute',
        component: GiftCardNav,
      },
      {
        path: 'purchase',
        name: 'GiftCardPurchase',
        component: GiftCardPurchase,
      },
      {
        path: 'purchase/:configId/giftee',
        name: 'GiftCardGifteeSearch',
        component: GiftCardGifteeSearch,
      },
    ]
  },
  {
    path: '/giftcard/redeem',
    name: 'GiftCardRedeem',
    component: GiftCardRedeem,
  },
  {
    path: '/giftcard/redeem/product',
    name: 'GiftCardRedeemProductRoute',
    component: GiftCardRedeemProduct,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router